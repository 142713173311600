/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Important bits"), "\n", React.createElement(_components.p, null, "Dark mode toggle without the flash of default theme:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "CSS variables for color theming"), "\n", React.createElement(_components.li, null, "Put ", React.createElement(_components.code, null, "data-theme"), " attribute on ", React.createElement(_components.code, null, "<html>"), ", not ", React.createElement(_components.code, null, "<body>"), ", so we can run the JS before the DOM finishes rendering"), "\n", React.createElement(_components.li, null, "Run local storage check in the ", React.createElement(_components.code, null, "<head>")), "\n", React.createElement(_components.li, null, "JS for toggle button click handler can come after render"), "\n"), "\n", React.createElement(_components.h3, null, "HTML"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<!DOCTYPE html>\n<html lang=\"en\" data-theme=\"light\">\n    <head>\n        <meta charset=\"utf-8\" />\n        <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\" />\n        ...\n        <script>\n            // If there's a theme stored in localStorage, use it on the <html>\n            const localStorageTheme = localStorage.getItem('theme');\n            if (localStorageTheme) {\n                document.documentElement.setAttribute(\n                    'data-theme',\n                    localStorageTheme\n                );\n            }\n        </script>\n    </head>\n    <body>\n        <div class=\"theme-toggle\">\n            <button\n                class=\"theme-toggle-btn js-theme-toggle\"\n                aria-label=\"Activate dark mode\"\n                title=\"Activate dark mode\"\n            >\n                <!--\n        <svg class=\"light-mode\">\n          <use xlink:href=\"#sun\"></use>\n        </svg>\n        <svg class=\"dark-mode\">\n          <use xlink:href=\"#moon\"></use>\n        </svg>\n        -->\n            </button>\n        </div>\n\n        <script src=\"app.js\"></script>\n    </body>\n</html>\n")), "\n", React.createElement(_components.h3, null, "CSS Variables"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-css"
  }, ":root {\n    --bg: #ffffff;\n    --text: #000000;\n}\n\n[data-theme='dark'] {\n    --bg: #000000;\n    --text: #ffffff;\n}\n")), "\n", React.createElement(_components.h3, null, "JavaScript"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const themeToggleBtn = document.querySelector('.js-theme-toggle');\n\nthemeToggleBtn.addEventListener('click', () => onToggleClick());\n\nconst onToggleClick = () => {\n    const { theme } = document.documentElement.dataset;\n    const themeTo = theme && theme === 'light' ? 'dark' : 'light';\n    const label = `Activate ${theme} mode`;\n\n    document.documentElement.setAttribute('data-theme', themeTo);\n    localStorage.setItem('theme', themeTo);\n\n    themeToggleBtn.setAttribute('aria-label', label);\n    themeToggleBtn.setAttribute('title', label);\n};\n")), "\n", React.createElement(_components.h3, null, "Resources"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/"
  }, "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/"
  }, "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/"
  }, "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/"
  }, "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/"
  }, "https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
